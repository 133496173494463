import React, { useState, useRef, useEffect, useCallback, memo } from 'react';
import '../styles/PhotoWindow.css';
import { Rnd } from 'react-rnd';
import CloseBtn from './CloseBtn';
import TogMaxBtn from './MaxTogBtn';
import { useGesture } from '@use-gesture/react';

const PhotoWindow = memo(
  ({
    imgURL,
    onClose,
    onSelect,
    isSelected,
    shouldClean,
    index,
    onAnimationTriggered,
    total,
    shouldScatter,
  }) => {
    const [width, setWidth] = useState(undefined);
    const [height, setHeight] = useState(undefined);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);
    const [imgLoaded, setImgLoaded] = useState(false);
    const [hoveringRnd, setHoveringRnd] = useState(false);
    const [hoveringBtns, setHoveringBtns] = useState(false);
    const [maximized, setMaximized] = useState(false);
    const [preMaxWidth, setPreMaxWidth] = useState(undefined);
    const [preMaxHeight, setPreMaxHeight] = useState(undefined);
    const [preMaxX, setPreMaxX] = useState(0);
    const [preMaxY, setPreMaxY] = useState(0);
    const [dragging, setDragging] = useState(false);
    const [pinching, setPinching] = useState(false);
    const [originX, setOriginX] = useState(0);
    const [originY, setOriginY] = useState(0);
    const [lastOffset, setLastOffset] = useState(1);
    const [scatteredX, setScatteredX] = useState(undefined);
    const [scatteredY, setScatteredY] = useState(undefined);

    const imgRef = useRef(null);

    const animate = useCallback(
      (targetX, targetY, targetWidth, targetHeight) => {
        const animationDuration = 1000; // Milliseconds
        const startTime = performance.now();

        const animateStep = (currentTime) => {
          const elapsedTime = currentTime - startTime;
          const ratio = Math.min(elapsedTime / animationDuration, 1);

          setX(
            (prevX) => parseFloat(prevX) + (targetX - parseFloat(prevX)) * ratio
          );
          setY(
            (prevY) => parseFloat(prevY) + (targetY - parseFloat(prevY)) * ratio
          );
          setWidth(
            (prevWidth) => prevWidth + (targetWidth - prevWidth) * ratio
          );
          setHeight(
            (prevHeight) => prevHeight + (targetHeight - prevHeight) * ratio
          );

          if (ratio < 1) {
            requestAnimationFrame(animateStep);
          }
        };

        requestAnimationFrame(animateStep);
        onAnimationTriggered();
      },
      [onAnimationTriggered]
    );

    const scatter = useCallback(
      (animateScatter = false) => {
        const scatteredXVal =
          scatteredX === undefined ? Math.random() : scatteredX;
        const scatteredYVal =
          scatteredY === undefined ? Math.random() : scatteredY;
        setScatteredX(scatteredXVal);
        setScatteredY(scatteredYVal);

        const scale =
          window.innerWidth > window.innerHeight
            ? (0.5 * window.innerHeight) / imgRef.current.height
            : (0.5 * window.innerWidth) / imgRef.current.width;
        let newWidth = imgRef.current.width * scale;
        let newHeight = imgRef.current.height * scale;
        let newX = (scatteredXVal * (window.innerWidth - newWidth)).toFixed();
        let newY = (scatteredYVal * (window.innerHeight - newHeight)).toFixed();

        if (animateScatter) {
          animate(newX, newY, newWidth, newHeight);
        } else {
          setWidth(newWidth);
          setHeight(newHeight);
          setX(newX);
          setY(newY);
        }
      },
      [scatteredX, scatteredY, animate]
    );

    const bind = useGesture({
      onPinch: (state) => {
        const scale = 1 + state.offset[0] - lastOffset;
        const newWidth = width * scale;
        const newHeight = height * scale;

        setWidth(newWidth);
        setHeight(newHeight);
        setLastOffset(state.offset[0]);
        setPinching(state.pinching);
        setOriginX(state.origin[0] - newWidth / 2);
        setOriginY(state.origin[1] - newHeight / 2);
      },
      // Must include these here rather than on the component so they don't interfere
      onMouseEnter: () => setHoveringRnd(true),
      onMouseLeave: () => setHoveringRnd(false),
    });

    useEffect(() => {
      const cleanUp = () => {
        let cols = Math.ceil(Math.sqrt(total));
        let rows = Math.ceil(total / cols);

        const padding = 5;

        const totalWidth = window.innerWidth - (cols + 1) * padding;
        const totalHeight = window.innerHeight - (rows + 1) * padding;

        let idealWidth = totalWidth / cols;
        let idealHeight = totalHeight / rows;
        if (idealWidth / idealHeight > 1.5) {
          idealWidth = 1.5 * idealHeight;
        } else {
          idealHeight = idealWidth / 1.5;
        }

        const aspect = width / height;
        const idealAspect = idealWidth / idealHeight;
        const targetWidth =
          idealAspect > aspect ? idealHeight * aspect : idealWidth;
        const targetHeight =
          idealAspect > aspect ? idealHeight : idealWidth / aspect;

        const row = Math.floor(index / cols);
        const col = index % cols;

        const totalWidthDiff = totalWidth - idealWidth * cols;
        const totalHeightDiff = totalHeight - idealHeight * rows;

        const widthDiff = idealWidth - targetWidth;
        const heightDiff = idealHeight - targetHeight;

        const targetX =
          totalWidthDiff / 2 +
          widthDiff / 2 +
          padding +
          col * (idealWidth + padding);
        const targetY =
          totalHeightDiff / 2 +
          heightDiff / 2 +
          padding +
          row * (idealHeight + padding);

        animate(targetX, targetY, targetWidth, targetHeight);
      };

      if (imgLoaded && shouldClean) {
        cleanUp();
      }
    }, [
      shouldClean,
      width,
      height,
      index,
      onAnimationTriggered,
      total,
      animate,
      imgLoaded,
    ]);

    useEffect(() => {
      if (shouldScatter) {
        scatter(true);
      }
    }, [shouldScatter, scatter]);

    const handleLoad = () => {
      scatter();
      setImgLoaded(true);
    };

    const handleDragStart = () => {
      onSelect();
      setDragging(true);
    };

    const handleDrag = (e, d) => {
      if (pinching) {
        setX(originX);
        setY(originY);
      }
    };

    const handleDragStop = (e, d) => {
      if (pinching) {
        setX(originX);
        setY(originY);
      } else {
        setX(d.x);
        setY(d.y);
      }
      setDragging(false);
    };

    const handleResizeStart = () => {
      setMaximized(false);
      onSelect();
    };

    const handleResizeStop = (e, direction, ref, delta, position) => {
      setWidth(width + delta.width);
      setHeight(height + delta.height);
      setX(position.x);
      setY(position.y);
    };

    const togMax = () => {
      if (maximized) minimize();
      else maximize();
    };

    const maximize = () => {
      const windowAspect = window.innerWidth / window.innerHeight;
      const photoWindowAspect = width / height;
      const scale =
        windowAspect > photoWindowAspect
          ? window.innerHeight / height
          : window.innerWidth / width;
      const newWidth = width * scale;
      const newHeight = height * scale;
      const newX = (window.innerWidth / 2 - newWidth / 2).toFixed();
      const newY = (window.innerHeight / 2 - newHeight / 2).toFixed();

      setWidth(newWidth);
      setHeight(newHeight);
      setX(newX);
      setY(newY);
      setMaximized(true);
      setPreMaxWidth(width);
      setPreMaxHeight(height);
      setPreMaxX(x);
      setPreMaxY(y);

      onSelect();
    };

    const minimize = () => {
      setWidth(preMaxWidth);
      setHeight(preMaxHeight);
      setX(preMaxX);
      setY(preMaxY);
      setMaximized(false);

      onSelect();
    };

    return (
      <Rnd
        className={dragging ? 'rnd dragging' : 'rnd'}
        size={{ width, height }}
        position={{ x, y }}
        disableDragging={hoveringBtns}
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragStop={handleDragStop}
        onResizeStart={handleResizeStart}
        onResizeStop={handleResizeStop}
        lockAspectRatio={true}
        minWidth={100}
        {...bind()}
      >
        <img
          className={imgLoaded ? 'img loaded' : 'img'}
          src={imgURL}
          alt='photography'
          ref={imgRef}
          onLoad={handleLoad}
        />
        <div
          className={
            hoveringRnd || isSelected ? 'btn-bar visible' : 'btn-bar hidden'
          }
          onMouseEnter={() => setHoveringBtns(true)}
          onMouseLeave={() => setHoveringBtns(false)}
        >
          <TogMaxBtn onClick={togMax} maxMode={!maximized} />
          <CloseBtn onClick={onClose} />
        </div>
      </Rnd>
    );
  }
);

export default PhotoWindow;
