import React from 'react';
import '../styles/TrashBtn.css';
import { Button } from 'react95';
import trashEmptyIcon from '../images/windows95trash_empty.png';
import trashFilledIcon from '../images/windows95trash_filled.png';
import trashSound from './sounds/windows-xp-recycling-bin.wav';

const TrashBtn = (props) => {
  const trashAudio = new Audio(trashSound);

  return (
    <div className='trash-btn'>
      <Button
        variant='flat'
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          outline: 'none',
          width: 48,
          height: 48,
        }}
        onClick={() => {
          props.onClick();
          trashAudio.play();
        }}
      >
        <img
          src={props.emptiedOnce ? trashFilledIcon : trashEmptyIcon}
          alt='trash'
        />
      </Button>
    </div>
  );
};

export default TrashBtn;
