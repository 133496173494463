import React from 'react';
import '../styles/CleanBtn.css';
import { Button } from 'react95';
import gridIcon from '../images/grid-2.png';

const CleanUpBtn = (props) => {
  return (
    <div className={`clean-btn${props.isClean ? '' : ' tilt'}`}>
      <Button
        variant='flat'
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          outline: 'none',
          width: 48,
          height: 48,
        }}
        onClick={() => {
          props.onClick();
        }}
      >
        <img src={gridIcon} width={'100%'} alt='clean-up' />
      </Button>
    </div>
  );
};

export default CleanUpBtn;
