import React from 'react';
import Home from './components/Home';
import Mobile from './components/Mobile';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

const App = () => {
  if (isMobile) return <Mobile />;
  else
    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home load={false} />} />
          <Route path='/search' element={<Home load={true} />} />
        </Routes>
      </BrowserRouter>
    );
};

export default App;
