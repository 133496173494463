import React, { useEffect, useRef } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { styleReset, TextField, Button } from 'react95';
// pick a theme of your choice
import theme from 'react95/dist/themes/original';
// original Windows95 font (optionally)
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
`;

const SearchBar = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (props.imgURLs.length === 0) inputRef.current.focus();
    else inputRef.current.blur();
  }, [props.imgURLs]);

  return (
    <div className='searchbar'>
      <form className='searchform' onSubmit={props.onSubmit}>
        <GlobalStyles />
        <ThemeProvider theme={theme}>
          <div>
            <div style={{ display: 'flex' }}>
              <TextField
                ref={inputRef}
                variant='flat'
                onChange={props.onChange}
                value={props.formData}
                fullWidth
              />
              <Button
                variant='flat'
                type='submit'
                style={{ marginLeft: 4, marginTop: 2, height: 31.2 }}
              >
                Search
              </Button>
            </div>
          </div>
        </ThemeProvider>
      </form>
    </div>
  );
};

export default SearchBar;
