import { useState, useEffect } from 'react';
import '../styles/SearchBar.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchBar from './SearchBar';
import TrashBtn from './TrashBtn';
import CleanBtn from './CleanBtn';
import PhotoWindow from './PhotoWindow';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../firebase/config';

const Home = (props) => {
  const [load, setLoad] = useState(props.load);
  const [imgURLs, setImgURLs] = useState([]);
  const [currentlySelected, setCurrentlySelected] = useState(null);
  const [formData, setFormData] = useState('');
  const [searchParams] = useSearchParams();
  const [emptiedOnce, setEmptiedOnce] = useState(false);
  const [shouldClean, setShouldClean] = useState(false);
  const [shouldScatter, setShouldScatter] = useState(false);
  const [isClean, setIsClean] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsClean(false);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchImages = async (query) => {
      try {
        console.log('query:', query);
        const baseUrl = process.env.REACT_APP_BACKEND_URL_OVERRIDE ?? '';
        const url = `${baseUrl}/api/v1/images${
          query ? `?search=${query}` : ''
        }`;
        console.log('url:', url);
        const response = await fetch(url);
        const data = await response.json();
        return data.urls;
      } catch (error) {
        console.error('Error fetching images:', error);
        return [];
      }
    };

    const setImgURLsWithDelay = async (urls) => {
      const delay = 50;
      for (let i = 0; i < urls.length; i++) {
        setTimeout(() => {
          setImgURLs((prevImgURLs) => {
            const newURL = urls[i];
            if (!prevImgURLs.includes(newURL)) {
              return [...prevImgURLs, newURL];
            }
            return prevImgURLs;
          });
        }, delay * i);
      }
    };

    if (load) {
      setLoad(false);
      const query = searchParams.get('q');
      setFormData(query);
      fetchImages(query)
        .then((urls) => {
          console.log('URLs:', urls);
          return setImgURLsWithDelay(urls);
        })
        .catch((err) => console.error('Error:', err));
    }
  }, [load, searchParams]);

  const handleChange = (e) => {
    setFormData(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsClean(false);

    navigate(`/search?q=${formData}`);

    logEvent(analytics, 'search', {
      search_term: formData,
    });

    setImgURLs(() => []);

    setLoad(true);
  };

  const handleTrashClick = () => {
    if (imgURLs.length > 0 && !emptiedOnce) setEmptiedOnce(true);
    setImgURLs(() => []);
    setIsClean(false);
  };

  const handleClose = (imgURLToRemove) => {
    setImgURLs((prevImgURLs) => {
      const newImgURLs = prevImgURLs.filter(
        (imgURL) => imgURL !== imgURLToRemove
      );
      return newImgURLs;
    });
    if (!emptiedOnce) setEmptiedOnce(true);
    setIsClean(false);
  };

  const handleSelect = (imgURL) => {
    setCurrentlySelected(imgURL);
    setIsClean(false);
  };

  const handleCleanClick = () => {
    if (imgURLs.length > 0) {
      setShouldScatter(isClean);
      setShouldClean(!isClean);
    } else {
      setShouldScatter(false);
      setShouldClean(false);
    }
    setIsClean((prev) => !prev);
  };

  // Extra guardrail against duplicates
  const uniqueImgURLs = [...new Set(imgURLs)];
  const photoWindows = uniqueImgURLs.map((imgURL, index) => {
    return (
      <PhotoWindow
        key={imgURL}
        imgURL={imgURL}
        onClose={() => handleClose(imgURL)}
        onSelect={() => handleSelect(imgURL)}
        isSelected={imgURL === currentlySelected}
        shouldClean={shouldClean}
        index={index}
        onAnimationTriggered={() => {
          setShouldClean(false);
          setShouldScatter(false);
        }}
        total={uniqueImgURLs.length}
        shouldScatter={shouldScatter}
      />
    );
  });
  photoWindows.sort((a, b) => {
    return a.props.imgURL === currentlySelected
      ? 1
      : b.props.imgURL === currentlySelected
      ? -1
      : 0;
  });

  return (
    <div className='app'>
      <SearchBar
        onChange={handleChange}
        onSubmit={handleSubmit}
        load={load}
        imgURLs={imgURLs}
        formData={formData}
      />
      <div>{photoWindows}</div>
      <TrashBtn onClick={handleTrashClick} emptiedOnce={emptiedOnce} />
      <CleanBtn onClick={handleCleanClick} isClean={isClean} />
    </div>
  );
};

export default Home;
