import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { styleReset } from 'react95';
// original Windows95 font (optionally)
import ms_sans_serif from 'react95/dist/fonts/ms_sans_serif.woff2';
import ms_sans_serif_bold from 'react95/dist/fonts/ms_sans_serif_bold.woff2';
import windows95folder from '../images/windows95folder.png';

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
`;

const Mobile = () => {
  return (
    <div>
      <GlobalStyles />
      <div
        style={{
          position: 'absolute',
          textAlign: 'center',
          width: 300,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: 18,
        }}
      >
        <p className='notify'>Please view on a desktop browser.</p>
        <img src={windows95folder} alt='folder' style={{ marginTop: 20 }} />
      </div>
    </div>
  );
};

export default Mobile;
