import React from 'react';
import { Button } from 'react95';
import { ThemeProvider } from 'styled-components';
import theme from 'react95/dist/themes/tokyoDark';

const CloseBtn = (props) => {
  return (
    <div className='close-btn'>
      <ThemeProvider theme={theme}>
        <Button
          style={{ height: 32, backgroundColor: 'transparent', border: 'none' }}
          variant='flat'
          onTouchStart={props.onClick}
          onClick={props.onClick}
        >
          X
        </Button>
      </ThemeProvider>
    </div>
  );
};

export default CloseBtn;
